.header_wrapper{
  background-color: grey;
}
.nav-item{
   padding: 10px;
}
.nav-link{
    color: aquamarine !important;
    font-size: 20px;
    font-weight: 600;
    padding: 10px;
    cursor: pointer;
}
.nav1{
    margin-top: 7px;
}
.main-btn2{
    color: aqua;
    background-color:rgb(77, 76, 76) ;
    text-decoration: none;
    padding: 10px 20px;
    border-radius: 50px;
    transition: 0.6s;
    margin-top: 20px;
   
}
.main-btn2:hover{
    border:1px solid rgb(25, 24, 24);
    color: grey;
    background-color: transparent;
}
.navbar{
    background-color: rgb(31, 30, 30);
    
}
.menu-navbar-nav{
 
    text-align: center;
    padding-bottom: 15px;
}
.navbar-toggler:focus{
    box-shadow: none;
    outline: none;
}
