body{
   
   
    
}
.title{
    position: relative;
}
.title_head{
    font-weight: 700;
    font-size: 7rem;
    text-align: center;
    opacity: 1;
    color: rgb(53, 53, 53);
}
.title_para{
position: absolute;
top: 40px;
left:42%;
font-size: 2.2rem;
font-weight: 600;
font-style: "Poppins";
color: white;
border-bottom: 2px solid aqua;

}
::-webkit-scrollbar{
    width: 6px;
}
::-webkit-scrollbar-track{
    background: black;
}
::-webkit-scrollbar-thumb{
    background: linear-gradient(-224deg, rgb(0, 255, 238) 0,rgb(0, 179, 255) 100%);
}

@media (max-width:576px){
    .title_head{
        font-weight: 700;
        font-size:2.5rem;
        text-align: center;
        opacity: 0.5;
    }
    .title_para{
        position: absolute;
        top: 10px;
        left:25%;
        font-size: 1rem;
        font-weight: 600;
        font-style: "Poppins";
        color: white;
        border-bottom: 2px solid aqua;
        
        }
}
@media (max-width:768px){
    .title_head{
        font-weight: 700;
        font-size:5rem;
        text-align: center;
        opacity: 0.5;
    }
    
}
