*{
    box-sizing: border-box;
}
.edu_title{
    color: black;
}
.education{
    background-color: rgb(49, 49, 49);
    
    display: flex;

   
    justify-content: center;
    flex-direction: column;
    padding: 50px;
}
.education>h1{
    text-align: center;
    margin-bottom: 50px;
}
.edu_contain{
    margin-top: 40px;
    width: 60% !important;
  color: white;
  border: 1px solid aqua;
   
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
 .edu_wrapper{
    
    justify-content: center;
    align-items: center;
    text-align: center;
 }
 .columns{
    padding: 0 50px;
 }
.columns:hover{
    
    background-color: aqua;
    color: rgb(47, 46, 46);
    border-radius: 40px;
    
}


/* Responsive css */


@media (max-width:768px){
    .edu_title{
        font-size: 60px;
     }
     .edu_para{
        font-size: 30px;
        position: absolute;
        top: 10px;
        left: 40%;
     }

     .edu_wrapper{
        margin-top: 40px;}
        .edu_contain{
            margin-top: 40px;
        }
      
}

@media(max-width:576px){
    .edu_contain{
        margin-top: 80px;
       border: none;
      color: white;
     
        
       
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .edu_wrapper{
        border: 1px solid aqua;
        border-radius: 50px;
        padding: 20px 0;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-bottom: 50px;
     }
     .edu_title{
        font-size: 40px;
     }
     .edu_para{
        font-size: 22px;
        position: absolute;
        top: 8px;
        left:35%;
     }
     
}