.hero_section{
  
   color: rgb(88, 86, 86);
   background-image:linear-gradient(to top, rgb(23, 211, 150), rgb(7, 247, 247));
   display: flex;
   flex-direction: column;
   height: 100vh;
   justify-content: center;
   align-items: center;
}
.hero_section h1,h2{
    text-align: center;
}
.hero_head{
    font-size: 40px;
}
.name{
    font-size: 60px;
    font-weight: 700;
}
.role{
    font-size: 50px;
    font-weight: 500;
}
.main-btn{
    margin-top: 20px;
    padding:  20px 30px;
    border-radius: 50px;
    font-size: 20px;
    color: aquamarine;
   border: none;
    background-color: rgb(31, 30, 30);
    transition: 0.8s;

}
.main-btn:hover{
    border: 1px solid rgb(31, 30, 30);
    background-color: transparent;
    color: black;
}
@media (max-width:576px){
    .hero_head{
        font-size: 20px;
    }
    .name{
        font-size: 40px;
        font-weight: 700;
    }
    .role{
        font-size: 30px;
        font-weight: 500;
    }
}