.contact_wrapper{
    background-color: rgba(17, 17, 17);
    padding: 80px 0;
}
.contact_row{
    color: aqua;
    display: flex;
    justify-content: center;
    
}
form{
   
    display: flex;
    justify-content: center;

   flex-wrap: wrap;
   margin: 60px 0 60px 0;
}
.form_input{
    width: 100%;
    background-color: transparent;
    padding: 10px 20px;
    border-radius: 50px;
    margin-bottom: 20px;
    border: 1px solid aqua;
    color: white;
   
   
}
.form_input:focus {
    border: 1px solid white;
    color: white;
    outline: none;
    background-color: rgba(96, 219, 207, 0.7);
}

.submit-btn{
    background-color: aqua;
    border: none;
    color: rgb(36, 35, 35);
   width: 30%;
   padding: 10px;
   font-size: 20px;
   font-weight: 400;
   border-radius: 50px;
   transition: 1s;
  
}
.submit-btn:hover{
    color: aqua;
   background-color: transparent;
   border: 1px solid aqua;
   font-size: 20px;
   font-weight: 400;
   
}
.footer{
    display: flex;
    justify-content: center;
    margin-bottom: 60px;
}
.contact_icon{
    font-size: 50px;
    padding: 10px;
  
    background-color: rgb(55, 54, 54);
    border-radius: 50%;
    transition: 0.5s;
}
.contact_icon:hover{
    background-color: transparent;
    border: 1px solid aqua;
    transform: translatey(-30px);
}
.col_icon{
    display: flex;
    justify-content: center;
}



@media(max-width:768px){
    .contact_title{
        font-size: 60px;
    }
    .contact-para{
        font-size: 25px;
    left: 42%;
    top: 20px;
       }
}

@media(max-width:576px){
    .contact_title{
        font-size: 40px;
    }
    .contact-para{
        font-size: 22px;
    left:32%;
    top: 5px;
    }
    .contact_icon{
     font-size: 40px;
     padding: 5px;
     background-color: rgb(55, 54, 54);
     border-radius: 50%;
     transition: 0.5s;
 }
 
    
 }